@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #ffd200;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.17rem;
}
p {
  font-size: 1rem;
}
body::-webkit-scrollbar {
  display: none;
}
