.App {
  background-color: gray;
  @apply bg-background   min-h-[100vh] px-[5vw];
}

button {
  @apply px-4 py-2 text-white bg-gray-700 rounded-md;
}
button:disabled {
  @apply bg-gray-500 cursor-not-allowed;
}

.input {
  @apply px-4 py-2 border border-gray-900 rounded-md;
}
